<template>
  <div class="details">
    <div class="details_top">
      <div class="particulars">
        <particulars @child-event="parentEvent" ref="detail"></particulars>
      </div>
      <div class="bidding_message">
        <div class="bidding_content" v-if="status != 2">
          <!-- <span style="font-size: 25px">竞价信息</span> -->
          <!-- 我参与的进行中加价详情时显示 -->
          <!-- 我参与的进行中加价详情时显示 -->
          <!-- ///////////////////////////////////////////////// -->
          <!-- 发布一开始和参与的详情使用 -->
          <div
            :class="listType === 0 ? 'message' : 'messages'"
            v-if="status == 1"
          >
            <div>
              <span>当前最高价</span>
              <span>
                <span
                  v-for="(item, index) in data.goodsCategoryList"
                  :key="index"
                >
                  <!-- {{ item.goodsName }}:<span style="color: #d0111a">￥</span> -->
                  <span style="color: #d0111a; font-size: 34px">{{
                    item.maxOrMinOfferPrice
                  }}</span
                  >元/吨{{
                    index == data.goodsCategoryList.length - 1 ? "" : " | "
                  }}
                </span>
              </span>
              <CountDown
                :endDate="`${data.residueTime}`"
                v-if="data.residueTime"
              ></CountDown>
            </div>
            <div v-if="listType == 0" style="margin-right: 100px">
              <div
                v-if="listType == 0 && data.myOfferPric == ''"
                style="margin-top: 10px; font-size: 16px"
              >
                暂无报价
              </div>
            </div>
            <!-- <div>
              <div>拍卖中</div>
              <div>{{ headertime}}</div>
            </div> -->
          </div>
          <!-- 发布一开始和参与的详情使用 -->

          <!-- 未中标 -->
          <div
            class="loseabid"
            v-if="
              data.winningStatus == 0 &&
              data.listType == 0 &&
              data.tradeBidding.biddingStatus == 3
            "
          >
            <div class="loseabid_no">未中标</div>
            <div class="loseabid_size">
              保证金将在1-2工作日内退回至您的现金余额
            </div>
          </div>
          <!-- 、、、、、、、、未中标、、、、、、、 -->
        </div>
        <!-- 我参与的进行中加价 -->
        <div
          class="quote form-box"
          style="align-items: flex-start"
          v-if="status == 1 && listType != 1"
        >
          <div>
            <div class="form">
              <el-form :model="ruleForm" ref="form" label-width="90px">
                <el-form-item
                  label="可供应数量"
                  label-width="100px"
                  prop="num"
                  v-if="selectTradeType == 1 && detailData.listType == 0"
                  class="offerPrice"
                >
                  <i class="el-icon-minus" @click="subNum"></i>
                  <el-input
                    v-model="ruleForm.num"
                    maxlength="2100000000"
                    @input="formatData('num')"
                  ></el-input>
                  <i class="el-icon-plus" @click="addNum"></i>
                  <span>吨</span>
                </el-form-item>
                <div
                  v-for="(item, index) in ruleForm.offerDetailList"
                  :key="index"
                >
                  <el-form-item
                    :label="index == 0 ? '报价金额' : ''"
                    :prop="'offerDetailList.' + index + '.offerPrice'"
                    :rules="{
                      required: true,
                      message: '请输入报价金额',
                      trigger: 'blur',
                    }"
                    label-width="150px"
                    v-if="selectTradeType == 2 && detailData.listType == 0"
                    class="offerPrice"
                  >
                    <div class="offerPriceItem">
                      <!-- <span class="goodsName">{{ item.goodsName }}</span> -->
                      <i class="el-icon-minus" @click="sub(index)"></i>
                      <el-input
                        v-model="ruleForm.offerDetailList[index].offerPrice"
                      ></el-input>
                      <i class="el-icon-plus" @click="add(index)"></i>
                      <span>元/吨</span>
                    </div>
                  </el-form-item>
                </div>
              </el-form>
            </div>
            <div class="btn-wrap">
              <el-button
                class="add hy-btn"
                @click="createOrder"
                v-if="selectTradeType == 1 && detailData.listType == 0"
                >确认供应</el-button
              >
              <el-button
                class="add hy-btn"
                @click="openPayBond"
                v-if="
                  selectTradeType == 2 &&
                  detailData.listType == 0 &&
                  payBond == 1
                "
                >立即报价</el-button
              >
              <el-button
                class="add hy-btn"
                @click="openPayBond"
                v-if="
                  selectTradeType == 2 &&
                  detailData.listType == 0 &&
                  payBond == 0
                "
                >支付保证金</el-button
              >
              <el-button
                class="follow"
                icon="el-icon-plus"
                @click="follow"
                v-if="detailData.listType == 0 && detailData.followFlag == 0"
                >收藏商品</el-button
              >
              <el-button
                class="follow"
                icon="el-icon-plus"
                @click="follows"
                v-if="detailData.listType == 0 && detailData.followFlag == 1"
                >取消收藏</el-button
              >
            </div>

            <!-- <div class="quote_al">
            <div>报价金额</div>
            <div class="subtract">
              <el-input-number
                :min="0"
                v-model="num"
                :step="lowestDiffPrice"
              ></el-input-number>
              <div>最低加价幅度{{ num }}元</div>
            </div>
            <div>元/吨</div>
          </div>
          <div class="quote_btn">
            <div @click="follow" v-if="data.followFlag == 0">收藏商品</div>
            <div @click="follow" v-else>取消收藏</div>
            <div @click="openPayBond">立即报价</div>
          </div> -->
          </div>
          <div
            v-if="selectTradeType == 2 && selectOrderType == 1"
            style="margin-left: 160px; color: red"
          >
            <!-- 多商品竞价需要将竞价内所含商品均报价才算报价成功&nbsp;&nbsp;&nbsp;&nbsp; -->
            最低加价{{ detailData.tradeBidding.lowestDiffPrice }}元
          </div>
          <div
            v-if="selectTradeType == 2 && selectOrderType == 2"
            style="margin-left: 160px; color: red"
          >
            <!-- 多商品竞价需要将竞价内所含商品均报价才算报价成功&nbsp;&nbsp;&nbsp;&nbsp; -->
            最低降价{{ detailData.tradeBidding.lowestDiffPrice }}元
          </div>
        </div>

        <!-- 我参与的进行中加价 -->
      </div>
    </div>
    <!-- 详情底部公司报价列表 -->
    <div
      class="company"
      v-if="
        (status == 1 || status == 2 || status == 3) &&
        computedLoginAndAuthentication
      "
    >
      <div
        class="company_list"
        style="display: flex; justify-content: space-between"
      >
        <div>竞价公司</div>
        <div style="cursor: pointer" @click="flushClick">
          <i class="el-icon-refresh"></i>
        </div>
      </div>
      <div class="company_table">
        <el-table
          :data="tableData"
          border
          :span-method="objectSpanMethod"
          :cell-style="cellStyle"
        >
          <el-table-column align="center" prop="companyName" label="竞价公司">
          </el-table-column>
          <el-table-column align="center" prop="goodsName" label="竞价商品名称">
          </el-table-column>
          <el-table-column align="center" prop="offerPrice" label="报价金额">
          </el-table-column>
          <el-table-column
            align="center"
            prop="offerCount"
            label="报价数量(吨)"
          >
          </el-table-column>
          <el-table-column prop="createTime" align="center" label="报价时间">
          </el-table-column>
          <el-table-column
            align="center"
            prop="winBidding"
            label="中标状态"
            width="180"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 审核竞价公司列表 test-->
    <div
      class="list"
      v-if="
        detailData.ifPublisher && detailData.tradeBidding.partNeedAudit == 1
      "
    >
      <div class="t" style="display: flex; justify-content: space-between">
        <div>审核参与竞拍企业</div>
        <div style="cursor: pointer" @click="listPartBidding">
          <i class="el-icon-refresh"></i>
        </div>
      </div>
      <el-table :data="partBiddingData" border style="width: 100%">
        <el-table-column align="center" prop="enterpriseName" label="竞价公司">
        </el-table-column>
        <el-table-column align="center" prop="telephone" label="注册电话">
        </el-table-column>
        <el-table-column prop="createTime" align="createTime" label="提交时间">
        </el-table-column>
        <el-table-column
          align="center"
          prop="auditStatus"
          label="状态"
          width="180"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.auditStatus == 0">
              <div>待审核</div>
            </div>
            <div v-if="scope.row.auditStatus == 1">
              <div>审核通过</div>
            </div>
            <div v-if="scope.row.auditStatus == 2">
              <div>审核拒绝</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="winBidding"
          label="操作"
          width="180"
        >
          <template slot-scope="scope">
            <el-button
              v-if="
                scope.row.auditStatus == 0 &&
                (detailData.tradeBidding?.biddingStatus == 0 ||
                  detailData.tradeBidding?.biddingStatus == 1)
              "
              @click="Approved(scope.row, 1)"
              type="text"
              size="small"
              >审核通过</el-button
            >
            <el-button
              v-if="
                scope.row.auditStatus == 0 &&
                (detailData.tradeBidding?.biddingStatus == 0 ||
                  detailData.tradeBidding?.biddingStatus == 1)
              "
              @click="Approved(scope.row, 2)"
              type="text"
              size="small"
              >审核拒绝</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="details_bottom">
      <regulation class="regulation_bottom" :data="data"></regulation>
    </div>
  </div>
</template>

<script>
import particulars from "@/layout/particulars.vue";
import regulation from "@/layout/regulation.vue";
import { tradeOffer } from "@/api/public.js";
import {
  reqIsPayBond,
  reqFollow,
  reqAddBiddingOffer,
  applyPartBidding,
  listPartBidding,
  auditPartBidding,
} from "@/api/hy";
import floatCompute from "@/utils/floatCompute.js";
// import CountDown from '@/components/countDown.vue'
import CountDown from "@/components/countDowns.vue";
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      ruleForm: {
        num: "",
        offerDetailList: [],
      },

      headertime: null,
      data: {},
      num: "",
      tableData: [],
      status: this.$route.query.status,
      time: 0,
      timer: "",
      timestamp: 0,
      page: 1,
      size: 10,
      lowestDiffPrice: 0,
      paybondShow: true,
      listType: null,
      detailData: {},
      selectTradeType: 2,
      selectOrderType: null,
      payBond: 0,
      partBiddingData: [],
    };
  },
  components: {
    particulars,
    regulation,
    CountDown,
    // CountDown,
  },
  mounted() {
    this.getList();
    // console.log(this.$router.query.goodsId);
    this.listType = this.$route.query.listType;
    this.selectOrderType = this.$route.query.selectOrderType;
  },
  watch: {},

  computed: {
    ...mapState({
      massage: (state) => state.user.massage,
    }),
    computedLoginAndAuthentication() {
      let status =
        this.massage == "未登录请前往登录" ||
        this.massage == "请前往认证后操作";
      return !status;
    },
  },
  methods: {
    Approved(row, type) {
      auditPartBidding({
        auditId: row.auditId,
        auditReason: "",
        auditStatus: type,
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "审核成功",
          });
          this.listPartBidding();
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    listPartBidding() {
      listPartBidding({
        biddingId: this.detailData.tradeBidding.biddingId,
      }).then((res) => {
        if (res.code == 0) {
          this.partBiddingData = res.data;
          console.log(res.data, "listPartBidding");
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    mergeCol(id, rowIndex) {
      // debugger;
      // 合并单元格
      // id：属性名
      // rowIndex：行索引值
      var idName = this.tableData[rowIndex][id]; // 获取当前单元格的值
      if (rowIndex > 0) {
        // 判断是不是第一行
        // eslint-disable-next-line eqeqeq
        if (this.tableData[rowIndex][id] != this.tableData[rowIndex - 1][id]) {
          // 先判断当前单元格的值是不是和上一行的值相等
          var i = rowIndex;
          var num = 0; // 定义一个变量i，用于记录行索引值并进行循环，num用于计数
          while (i < this.tableData.length) {
            // 当索引值小于table的数组长度时，循环执行
            if (this.tableData[i][id] === idName) {
              // 判断循环的单元格的值是不是和当前行的值相等
              i++; // 如果相等，则索引值加1
              num++; // 合并的num计数加1
            } else {
              i = this.tableData.length; // 如果不相等，将索引值设置为table的数组长度，跳出循环
            }
          }
          return {
            rowspan: num, // 最终将合并的行数返回
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0, // 如果相等，则将rowspan设置为0
            colspan: 1,
          };
        }
      } else {
        // 如果是第一行，则直接返回
        let i = rowIndex;
        let num = 0;
        while (i < this.tableData.length) {
          // 当索引值小于table的数组长度时，循环执行
          if (this.tableData[i][id] === idName) {
            i++;
            num++;
          } else {
            i = this.tableData.length;
          }
        }
        return {
          rowspan: num,
          colspan: 1,
        };
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0 || columnIndex == 4 || columnIndex == 5) {
        return this.mergeCol("offerId", rowIndex);
      }
    },
    subNum() {
      this.ruleForm.num = floatCompute.floatSub(+this.ruleForm.num, 1);
      if (this.ruleForm.num <= 0) {
        this.ruleForm.num = 0;
      }
    },
    follows() {
      this.$confirm("是否要取消收藏?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          reqFollow({
            goodsId: this.$route.query.goodsId,
            type: 1,
          }).then((res) => {
            if (res.code == 0) {
              this.$refs.detail.getlisst(this.$route.query.goodsId);
              this.$message({
                type: "success",
                message: "取消收藏成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    addNum() {
      this.ruleForm.num = floatCompute.floatAdd(+this.ruleForm.num, 1);
    },
    sub(index) {
      let data = this.detailData.tradeBidding;
      if (
        this.$route.query.selectTradeType == 2 &&
        this.$route.query.selectOrderType == 1
      ) {
        if (this.ruleForm.offerDetailList[index].offerPrice <= 0) {
          this.ruleForm.offerDetailList[index].offerPrice = 0;
        } else if (
          this.ruleForm.offerDetailList[index].offerPrice ==
          this.ruleForm.offerDetailList[index].goodsPrice
        ) {
          this.ruleForm.offerDetailList[index].offerPrice =
            this.ruleForm.offerDetailList[index].goodsPrice;
        } else {
          this.ruleForm.offerDetailList[index].offerPrice -=
            data.lowestDiffPrice;
        }
      } else {
        if (
          this.ruleForm.offerDetailList[index].offerPrice -
            data.lowestDiffPrice <=
          0
        ) {
          this.ruleForm.offerDetailList[index].offerPrice = 0;
        } else if (this.ruleForm.offerDetailList[index].offerPrice <= 0) {
          this.ruleForm.offerDetailList[index].offerPrice = 0;
        } else if (
          this.ruleForm.offerDetailList[index].offerPrice <=
          this.ruleForm.offerDetailList[index].goodsPrice
        ) {
          this.ruleForm.offerDetailList[index].offerPrice =
            this.ruleForm.offerDetailList[index].offerPrice -
            data.lowestDiffPrice;
        }
      }
    },
    add(index) {
      let data = this.detailData.tradeBidding;
      if (
        this.$route.query.selectTradeType == 2 &&
        this.$route.query.selectOrderType == 1
      ) {
        this.ruleForm.offerDetailList[index].offerPrice += data.lowestDiffPrice;
      } else {
        if (
          this.ruleForm.offerDetailList[index].offerPrice +
            data.lowestDiffPrice >=
          this.ruleForm.offerDetailList[index].offerPrice
        ) {
          this.ruleForm.offerDetailList[index].offerPrice =
            this.ruleForm.offerDetailList[index].goodsPrice;
        } else if (
          this.ruleForm.offerDetailList[index].offerPrice >=
          this.ruleForm.offerDetailList[index].goodsPrice
        ) {
          this.ruleForm.offerDetailList[index].offerPrice =
            this.ruleForm.offerDetailList[index].goodsPrice;
        } else {
          this.ruleForm.offerDetailList[index].offerPrice =
            this.ruleForm.offerDetailList[index].offerPrice +
            data.lowestDiffPrice;
        }
      }
    },
    flushClick() {
      this.getList();
    },
    formatTableDate(list) {
      if (!list || list.length == 0) {
        return [];
      }
      let newList = [];
      list.forEach((item) => {
        item.offerDetailList.forEach((i) => {
          if (i.offerPrice == 0) {
            i.offerPrice = "*";
          } else {
            i.offerPrice = "￥" + i.offerPrice;
          }
          newList.push({ ...item, ...i });
        });
      });
      return newList;
    },
    getList() {
      reqIsPayBond(this.$route.query.goodsId).then((ret) => {
        console.log(ret);
        this.payBond = ret.data.payBond;
      });
      let config = {
        page: this.page,
        size: this.size,
        goodsId: this.$route.query.goodsId,
      };
      tradeOffer(config).then((res) => {
        let list = res.data.records;
        list.forEach((item) => {
          if (item.winBidding == 0) {
            item.winBidding = "未中标";
          } else if (item.winBidding == 1) {
            item.winBidding = "中标";
          } else if (item.winBidding == -1) {
            item.winBidding = " ";
          }
          if (item.offerPrice == 0) {
            item.offerPrice = "*";
          } else {
            item.offerPrice = "￥" + item.offerPrice;
          }
          if (item.createTime) {
            item.createTime = item.createTime.replace("T", " ");
          }
        });
        this.tableData = this.formatTableDate(list);
        console.log(this.tableData);
      });
    },
    //关注商品
    follow() {
      if (!this.beforhandle("personalCenter:auctionSale:partake:collect"))
        return;
      reqFollow({
        goodsId: this.$route.query.goodsId,
        type: 0,
      })
        .then((ret) => {
          if (ret.code == 0) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.$refs.detail.getlisst(this.$route.query.goodsId);
          } else {
            this.$message({
              type: "error",
              message: ret.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 表头样式
    headerCellStyle() {
      return "color:#333333;font-size:14px;height:45px;text-align:center;font-weight: 400;border:none;";
    },
    cellStyle(column) {
      if (column.columnIndex == 0) {
        return "text-align:center;border-bottom:1px dashed #E5E5E5;color:#0458BF;cursor:pointer;";
      } else if (column.columnIndex == 1) {
        return "text-align:center;border-bottom:1px dashed #E5E5E5;";
      } else if (column.columnIndex == 4 && column.row.winBidding == "未中标") {
        return "text-align:center;border-bottom:1px dashed #E5E5E5;color:#FE6205";
      } else if (column.columnIndex == 4 && column.row.winBidding == "中标") {
        return "text-align:center;border-bottom:1px dashed #E5E5E5;color:#0458BF";
      } else {
        return "text-align:center;border-bottom:1px dashed #E5E5E5;";
      }
    },
    //立即报价
    openPayBond() {
      if (!this.beforhandle("personalCenter:auctionSale:partake:offer")) return;
      let config = {
        goodsId: this.$route.query.goodsId,
        offerDetailList: this.ruleForm.offerDetailList.map((item) => {
          return {
            categoryId: item.categoryId,
            offerPrice: item.offerPrice,
          };
        }),
      };
      reqAddBiddingOffer(config).then((res) => {
        console.log(res);
        if (res.code == 1) {
          this.$message({
            type: "error",
            message: res.message,
          });
        } else {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          // this.$refs.detail.getlisst(this.$route.query.goodsId);
          this.getList();
        }
      });
    },
    // biddingOffer() {
    //   this.$refs.form.validate((valid) => {
    //     if (valid) {
    //       if (this.submiting) return;
    //       this.submiting = true;
    //       reqAddBiddingOffer({
    //         goodsId: this.$route.query.goodsId,
    //         offerPrice: this.ruleForm.offerPrice,
    //       })
    //         .then((ret) => {
    //           if (ret.code == 0) {
    //             this.$message({
    //               type: "success",
    //               message: "操作成功",
    //             });
    //             this.$router.back(-1);
    //           } else {
    //             this.$message({
    //               type: "error",
    //               message: ret.message,
    //             });
    //           }
    //           this.submiting = false;
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //         });
    //     } else {
    //       console.log("error submit!!");
    //       return false;
    //     }
    //   });
    // },
    parentEvent(data) {
      if (data.residueTime > 0) {
        this.Djs_time();
      }
      this.detailData = data;
      console.log(this.detailData, "detailData");
      if (this.$route.query.selectTradeType == 2) {
        this.ruleForm.offerDetailList = data.goodsCategoryList.map((item) => {
          return {
            categoryId: item.categoryId,
            offerPrice: item.goodsPrice,
            goodsPrice: item.goodsPrice,
            goodsName: item.goodsName,
          };
        });
        console.log(this.ruleForm);
      }
      if (data.tradeBidding) {
        this.lowestDiffPrice = data.tradeBidding.lowestDiffPrice;
        console.log(data);
        this.num = data.tradeBidding.floorPrice;
      }

      this.listPartBidding();
      this.data = data;
    },
    // 时间戳转换成时间
    toHHmmss(value) {
      var time;
      let result = parseInt(value / 1000);
      let hours =
        Math.floor(result / 3600) < 10
          ? "0" + Math.floor(result / 3600)
          : Math.floor(result / 3600);
      let minutes =
        Math.floor((result / 60) % 60) < 10
          ? "0" + Math.floor((result / 60) % 60)
          : Math.floor((result / 60) % 60);
      let seconds =
        Math.floor(result % 60) < 10
          ? "0" + Math.floor(result % 60)
          : Math.floor(result % 60);
      time = hours + ":" + minutes + ":" + seconds;
      return time;
    },
    Djs_time: function () {
      let _this = this;
      let timer = setInterval(() => {
        _this.time = _this.time + 1000;
        let a = _this.data.residueTime - _this.time;
        _this.headertime = _this.toHHmmss(a);
        if (a < 0) {
          clearInterval(timer);
          _this.timer = null;
        }
      }, 1000);
      if (_this.timer == null) {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  // padding: 20px;
  min-width: 1150px;
  width: 90%;
  margin: 0 auto;
  max-width: 1200px;
  background: white;

  .particulars {
    width: 100%;
    margin-top: 20px;
    // margin-left: 40px;
  }

  .details_bottom {
    margin-top: 20px;

    .regulation_bottom {
      // margin-left: 20px;
      width: 100%;
    }
  }

  .bidding_message {
    width: 100%;
    // margin-left: 20px;
    font-size: 20px;

    .bidding_content {
      // padding: 30px 50px;
      // border: 1px solid #ccc;
      // text-align: center;
      // height: 200px;
      width: 100%;
      min-height: 89px;
      background: #f8f8f8;
      line-height: 89px;
      padding-left: 15px;

      .message {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
      }

      .messages {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
      }

      .loseabid {
        margin-top: 50px;
        text-align: center;
        display: flex;

        .loseabid_no {
          margin-top: 20px;
          width: 100px;
          height: 50px;
          line-height: 50px;
          background: #ccc;
          color: black;
          font-weight: bolder;
        }

        .loseabid_size {
          margin-left: 20px;
          color: #d0111a;
        }

        // :nth-child(1) {
        //   margin: 0 auto;
        //   padding: 10px;
        //   width: 200px;
        //   background: #ccc;
        //   color: black;
        //   font-weight: bolder;
        // }
        // :nth-child(2) {
        //   font-size: 16px;
        //   margin-top: 10px;
        //   color: red;
        // }
      }
    }

    .quote {
      margin-top: 20px;
      padding: 30px 50px;
      border: 1px solid #ccc;
      // text-align: center;
      & > div {
        display: flex;
      }
      .quote_al {
        display: flex;
        // justify-content: space-between;
        justify-content: center;
        line-height: 40px;

        .subtract {
          margin: 0 10px;
          font-size: 15px;
          color: red;
        }
      }

      .quote_btn {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        :nth-child(1) {
          border: 1px solid #ccc;
          padding: 10px;
          background: #ccc;
          font-weight: bolder;
        }

        :nth-child(2) {
          border: 1px solid #ccc;
          padding: 10px 80px;
          background: #ccc;
          font-weight: bolder;
        }
      }
    }
  }

  .company {
    margin: 20px 0;

    .company_list {
      font-size: 18px;
      background: #e5e5e5;
      height: 45px;
      line-height: 45px;
      padding-left: 22px;
      padding-right: 22px;
      color: #000;
    }
  }
  .list {
    margin: 20px 0;

    .t {
      font-size: 18px;
      background: #e5e5e5;
      height: 45px;
      line-height: 45px;
      padding-left: 22px;
      padding-right: 22px;
      padding: 0 22px;
      color: #000;
    }

    .el-table {
      border: 1px solid #ebebeb;
    }
  }
}

::v-deep .el-link--inner {
  font-size: 14px;
  line-height: 50px;
  height: 50px;
}

.goods-detail {
  width: 1200px;
  padding: 25px 20px;
  background: white;
  border: 1px solid #e5e5e5;
  margin: 15px auto;

  &::before,
  &::after {
    display: table;
    content: "";
  }
}

.info {
  flex: 1;
  padding: 20px;
  border: 1px solid #222;
}

.right {
  width: 300px;
  flex-shrink: 0;
  margin-left: 20px;
}

.release-info {
  font-size: 18px;

  .title {
    text-align: center;
    margin-bottom: 30px;
  }

  p {
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.form-box {
  margin-top: 20px;
  background: #f7f7f7;
  // display: flex;
  // align-items: center;
  padding-top: 16px;

  .el-input {
    width: 100px;
  }
}

.form-box {
  .btn-wrap {
    .el-button {
      border-radius: 0;
      padding: 0;
      height: 40px;
    }

    .follow {
      padding: 0 5px;
      border-radius: 4px;
      height: 34px;
    }

    .add {
      width: 160px;
      border-radius: 4px;
      height: 34px;
      margin-left: 30px;
      margin-bottom: 16px;
    }
  }
}

.evaluate {
  margin-top: 40px;
}

.offerPrice {
  &::v-deep .el-input__inner {
    height: 36px !important;
    border-radius: 0;
  }

  i {
    display: inline-flex;
    height: 35px;
    width: 35px;
    align-items: center;
    justify-content: center;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    cursor: pointer;
  }

  span {
    display: inline-block;
    margin-left: 5px;
  }
}

.hy-confirm::v-deep .el-message-box__container {
  width: 330px !important;
}

.el-pagination {
  text-align: right;
  margin-top: 10px;
}

.list {
  .t {
    border: 1px solid #e5e5e5;
    background: #f7f7f7;
    padding: 14px 22px;
    font-size: 18px;
  }

  .el-table {
    border: 1px solid #ebebeb;
  }
}

.rule {
  margin-top: 20px;
}
</style>
