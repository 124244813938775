var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details"},[_c('div',{staticClass:"details_top"},[_c('div',{staticClass:"particulars"},[_c('particulars',{ref:"detail",on:{"child-event":_vm.parentEvent}})],1),_c('div',{staticClass:"bidding_message"},[(_vm.status != 2)?_c('div',{staticClass:"bidding_content"},[(_vm.status == 1)?_c('div',{class:_vm.listType === 0 ? 'message' : 'messages'},[_c('div',[_c('span',[_vm._v("当前最高价")]),_c('span',_vm._l((_vm.data.goodsCategoryList),function(item,index){return _c('span',{key:index},[_c('span',{staticStyle:{"color":"#d0111a","font-size":"34px"}},[_vm._v(_vm._s(item.maxOrMinOfferPrice))]),_vm._v("元/吨"+_vm._s(index == _vm.data.goodsCategoryList.length - 1 ? "" : " | ")+" ")])}),0),(_vm.data.residueTime)?_c('CountDown',{attrs:{"endDate":`${_vm.data.residueTime}`}}):_vm._e()],1),(_vm.listType == 0)?_c('div',{staticStyle:{"margin-right":"100px"}},[(_vm.listType == 0 && _vm.data.myOfferPric == '')?_c('div',{staticStyle:{"margin-top":"10px","font-size":"16px"}},[_vm._v(" 暂无报价 ")]):_vm._e()]):_vm._e()]):_vm._e(),(
            _vm.data.winningStatus == 0 &&
            _vm.data.listType == 0 &&
            _vm.data.tradeBidding.biddingStatus == 3
          )?_c('div',{staticClass:"loseabid"},[_c('div',{staticClass:"loseabid_no"},[_vm._v("未中标")]),_c('div',{staticClass:"loseabid_size"},[_vm._v(" 保证金将在1-2工作日内退回至您的现金余额 ")])]):_vm._e()]):_vm._e(),(_vm.status == 1 && _vm.listType != 1)?_c('div',{staticClass:"quote form-box",staticStyle:{"align-items":"flex-start"}},[_c('div',[_c('div',{staticClass:"form"},[_c('el-form',{ref:"form",attrs:{"model":_vm.ruleForm,"label-width":"90px"}},[(_vm.selectTradeType == 1 && _vm.detailData.listType == 0)?_c('el-form-item',{staticClass:"offerPrice",attrs:{"label":"可供应数量","label-width":"100px","prop":"num"}},[_c('i',{staticClass:"el-icon-minus",on:{"click":_vm.subNum}}),_c('el-input',{attrs:{"maxlength":"2100000000"},on:{"input":function($event){return _vm.formatData('num')}},model:{value:(_vm.ruleForm.num),callback:function ($$v) {_vm.$set(_vm.ruleForm, "num", $$v)},expression:"ruleForm.num"}}),_c('i',{staticClass:"el-icon-plus",on:{"click":_vm.addNum}}),_c('span',[_vm._v("吨")])],1):_vm._e(),_vm._l((_vm.ruleForm.offerDetailList),function(item,index){return _c('div',{key:index},[(_vm.selectTradeType == 2 && _vm.detailData.listType == 0)?_c('el-form-item',{staticClass:"offerPrice",attrs:{"label":index == 0 ? '报价金额' : '',"prop":'offerDetailList.' + index + '.offerPrice',"rules":{
                    required: true,
                    message: '请输入报价金额',
                    trigger: 'blur',
                  },"label-width":"150px"}},[_c('div',{staticClass:"offerPriceItem"},[_c('i',{staticClass:"el-icon-minus",on:{"click":function($event){return _vm.sub(index)}}}),_c('el-input',{model:{value:(_vm.ruleForm.offerDetailList[index].offerPrice),callback:function ($$v) {_vm.$set(_vm.ruleForm.offerDetailList[index], "offerPrice", $$v)},expression:"ruleForm.offerDetailList[index].offerPrice"}}),_c('i',{staticClass:"el-icon-plus",on:{"click":function($event){return _vm.add(index)}}}),_c('span',[_vm._v("元/吨")])],1)]):_vm._e()],1)})],2)],1),_c('div',{staticClass:"btn-wrap"},[(_vm.selectTradeType == 1 && _vm.detailData.listType == 0)?_c('el-button',{staticClass:"add hy-btn",on:{"click":_vm.createOrder}},[_vm._v("确认供应")]):_vm._e(),(
                _vm.selectTradeType == 2 &&
                _vm.detailData.listType == 0 &&
                _vm.payBond == 1
              )?_c('el-button',{staticClass:"add hy-btn",on:{"click":_vm.openPayBond}},[_vm._v("立即报价")]):_vm._e(),(
                _vm.selectTradeType == 2 &&
                _vm.detailData.listType == 0 &&
                _vm.payBond == 0
              )?_c('el-button',{staticClass:"add hy-btn",on:{"click":_vm.openPayBond}},[_vm._v("支付保证金")]):_vm._e(),(_vm.detailData.listType == 0 && _vm.detailData.followFlag == 0)?_c('el-button',{staticClass:"follow",attrs:{"icon":"el-icon-plus"},on:{"click":_vm.follow}},[_vm._v("收藏商品")]):_vm._e(),(_vm.detailData.listType == 0 && _vm.detailData.followFlag == 1)?_c('el-button',{staticClass:"follow",attrs:{"icon":"el-icon-plus"},on:{"click":_vm.follows}},[_vm._v("取消收藏")]):_vm._e()],1)]),(_vm.selectTradeType == 2 && _vm.selectOrderType == 1)?_c('div',{staticStyle:{"margin-left":"160px","color":"red"}},[_vm._v(" 最低加价"+_vm._s(_vm.detailData.tradeBidding.lowestDiffPrice)+"元 ")]):_vm._e(),(_vm.selectTradeType == 2 && _vm.selectOrderType == 2)?_c('div',{staticStyle:{"margin-left":"160px","color":"red"}},[_vm._v(" 最低降价"+_vm._s(_vm.detailData.tradeBidding.lowestDiffPrice)+"元 ")]):_vm._e()]):_vm._e()])]),(
      (_vm.status == 1 || _vm.status == 2 || _vm.status == 3) &&
      _vm.computedLoginAndAuthentication
    )?_c('div',{staticClass:"company"},[_c('div',{staticClass:"company_list",staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',[_vm._v("竞价公司")]),_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.flushClick}},[_c('i',{staticClass:"el-icon-refresh"})])]),_c('div',{staticClass:"company_table"},[_c('el-table',{attrs:{"data":_vm.tableData,"border":"","span-method":_vm.objectSpanMethod,"cell-style":_vm.cellStyle}},[_c('el-table-column',{attrs:{"align":"center","prop":"companyName","label":"竞价公司"}}),_c('el-table-column',{attrs:{"align":"center","prop":"goodsName","label":"竞价商品名称"}}),_c('el-table-column',{attrs:{"align":"center","prop":"offerPrice","label":"报价金额"}}),_c('el-table-column',{attrs:{"align":"center","prop":"offerCount","label":"报价数量(吨)"}}),_c('el-table-column',{attrs:{"prop":"createTime","align":"center","label":"报价时间"}}),_c('el-table-column',{attrs:{"align":"center","prop":"winBidding","label":"中标状态","width":"180"}})],1)],1)]):_vm._e(),(
      _vm.detailData.ifPublisher && _vm.detailData.tradeBidding.partNeedAudit == 1
    )?_c('div',{staticClass:"list"},[_c('div',{staticClass:"t",staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',[_vm._v("审核参与竞拍企业")]),_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.listPartBidding}},[_c('i',{staticClass:"el-icon-refresh"})])]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.partBiddingData,"border":""}},[_c('el-table-column',{attrs:{"align":"center","prop":"enterpriseName","label":"竞价公司"}}),_c('el-table-column',{attrs:{"align":"center","prop":"telephone","label":"注册电话"}}),_c('el-table-column',{attrs:{"prop":"createTime","align":"createTime","label":"提交时间"}}),_c('el-table-column',{attrs:{"align":"center","prop":"auditStatus","label":"状态","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.auditStatus == 0)?_c('div',[_c('div',[_vm._v("待审核")])]):_vm._e(),(scope.row.auditStatus == 1)?_c('div',[_c('div',[_vm._v("审核通过")])]):_vm._e(),(scope.row.auditStatus == 2)?_c('div',[_c('div',[_vm._v("审核拒绝")])]):_vm._e()]}}],null,false,3992279243)}),_c('el-table-column',{attrs:{"align":"center","prop":"winBidding","label":"操作","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
              scope.row.auditStatus == 0 &&
              (_vm.detailData.tradeBidding?.biddingStatus == 0 ||
                _vm.detailData.tradeBidding?.biddingStatus == 1)
            )?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.Approved(scope.row, 1)}}},[_vm._v("审核通过")]):_vm._e(),(
              scope.row.auditStatus == 0 &&
              (_vm.detailData.tradeBidding?.biddingStatus == 0 ||
                _vm.detailData.tradeBidding?.biddingStatus == 1)
            )?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.Approved(scope.row, 2)}}},[_vm._v("审核拒绝")]):_vm._e()]}}],null,false,3262481989)})],1)],1):_vm._e(),_c('div',{staticClass:"details_bottom"},[_c('regulation',{staticClass:"regulation_bottom",attrs:{"data":_vm.data}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }